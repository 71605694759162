import { gql, request } from "graphql-request"
import { AudiobookAttributes } from "graphql/audiobookAttributes"
import { useInfiniteQuery } from "react-query"

// Query name
export const audiobooksQueryName = "audiobooksQuery"

// GraphQL query
const query = gql`
  ${AudiobookAttributes()}

  query AudiobooksQuery(
    $storeCountry: String!
    $pageSize: Int!
    $offset: Int!
  ) {
    audiobooks(
      pageSize: $pageSize
      storeCountry: $storeCountry
      offset: $offset
      orderBy: [
        { key: "supplierRanking", direction: ASC }
        { key: "title", direction: ASC }
        # { key: "collectionTitle", direction: ASC }
        # { key: "collectionPartNumber", direction: ASC }
      ]
    ) {
      nodes {
        ...AudiobookAttributes
      }
      pageInfo {
        hasMore
      }
    }
  }
`

//
// Query for audiobooks from the content system
//
export const audiobooksQuery = async ({ queryKey, pageParam = 0 }) => {
  const [, { storeCountry, breakpoint }] = queryKey
  let pageSize = 0
  switch (breakpoint) {
    case "desktop":
    case "laptop":
      pageSize = 24
      break
    case "tablet":
      pageSize = 18
      break
    case "mobile":
      pageSize = 12
      break
    default:
      throw new Error(`Unrecognised breakpoint: ${breakpoint}`)
  }
  try {
    // Run GraphQL query to get content items
    const audiobooks = await request(
      process.env.GATSBY_GRAPHQL_CONTENT_ENDPOINT,
      query,
      {
        storeCountry: storeCountry,
        pageSize: pageSize,
        offset: pageParam,
      }
    )
    return audiobooks
  } catch (error) {
    throw new Error(error)
  }
}

//
// A hook to use the audiobooks content query
//
export const useAudiobooksQuery = (storeCountry, breakpoint) => {
  return useInfiniteQuery(
    [audiobooksQueryName, { storeCountry, breakpoint }],
    audiobooksQuery,
    {
      staleTime: Infinity,
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.audiobooks?.pageInfo?.hasMore) {
          return pages.length * lastPage.audiobooks.nodes.length
        } else {
          return undefined
        }
      },
      enabled: breakpoint !== undefined && storeCountry !== undefined,
    }
  )
}

import htmlToText from "html-to-text"
import React from "react"
import { StructuredData } from "./structuredData"

/**
 * Structured Data: Product metadata
 */
const StructuredDataProduct = ({
  isbn,
  name,
  author,
  imagePath,
  description,
  url,
  currency,
  price,
}) => {
  return (
    <StructuredData>
      {{
        "@context": "https://schema.org/",
        "@type": ["Product", "Audiobook"],
        isbn: isbn,
        name: name,
        author: {
          "@type": "Person",
          name: author,
        },
        image: `https://superfonik.imgix.net/${imagePath}`,
        description: htmlToText.convert(description, {
          wordwrap: false,
        }),
        offers: {
          "@type": "Offer",
          price: price,
          priceCurrency: currency,
          availability: "https://schema.org/OnlineOnly",
          url: url,
        },
      }}
    </StructuredData>
  )
}

export default StructuredDataProduct

import ContentCard from "components/contentCard/contentCard"
import Button from "components/controls/button"
import Metadata from "components/utils/metadata/metadata"
import StructuredDataProduct from "components/utils/metadata/structuredDataProduct"
import ModalDialog from "components/utils/modalDialog"
import Spinner from "components/utils/spinner"
import BreakpointContext from "context/breakpointContext"
import { ContentItemsProvider } from "context/contentItemsContext"
import { useCountries } from "context/countryContext"
import { Screen, useSetScreen } from "context/screenContext"
import { useContentItemsMap } from "hooks/useContentItemsMap"
import { useFooter } from "hooks/useFooter"
import { useMountOnLoad } from "hooks/useMountOnLoad"
import useSiteMetadata from "hooks/useSiteMetadata"
import { useTabBar } from "hooks/useTabBAr"
import GridLayout from "layouts/gridLayout"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { useAudiobooksQuery } from "reactQuery/queries/audiobooksQuery"

const StoreAudiobooks = ({ location, pageContext }) => {
  // Set the screen
  useSetScreen(Screen.STORE)

  // Site URL from static metadata
  // See: https://css-tricks.com/how-to-the-get-current-page-url-in-gatsby/#aa-method-3-generate-the-current-page-url-with-the-pathname-property-from-location-data
  const { siteUrl } = useSiteMetadata()

  // Country information from context
  const { storeCountry } = useCountries()

  // The current breakpoint
  const bpt = useContext(BreakpointContext)

  // Query for audiobooks
  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
  } = useAudiobooksQuery(storeCountry, bpt)

  // Join all pages of audiobooks together
  const audiobooks = useMemo(() => {
    let audiobooks = []
    audiobooks = data?.pages?.flatMap((page) => {
      return page.audiobooks?.nodes?.flatMap((audiobook) => {
        return audiobook
      })
    })
    return audiobooks
  }, [data])

  // Build a map of audiobook content items
  const contentItemsMap = useContentItemsMap(audiobooks)

  // Error state
  const [showError, setShowError] = useState(false)

  // Mount state
  const isMounted = useMountOnLoad()

  // Try and extract the selected item from the page context, if
  // this page has been built statically.
  const selectedItem = pageContext?.selectedItem

  // Generate the page title
  const pageTitle = useMemo(() => {
    if (selectedItem) {
      return `${selectedItem.title} by ${selectedItem.author} | Audiobooks`
    } else {
      return "Audiobooks"
    }
  }, [selectedItem])

  // Use cover art for the metadata image if there is a selected item
  const metadataImage = useMemo(() => {
    if (selectedItem && selectedItem.artwork) {
      return selectedItem.artwork
    } else {
      return undefined
    }
  }, [selectedItem])

  // Only display footer when the audiobooks have finished loading
  useFooter(isMounted && data !== undefined)

  // Use a Tab Bar
  useTabBar(true)

  useEffect(() => {
    if (error) {
      setShowError(true)
    }
  }, [error])

  return (
    <>
      {/* eslint-disable react/jsx-pascal-case */}
      <Metadata
        title={pageTitle}
        description={selectedItem ? selectedItem.description : undefined}
        image={metadataImage}
        pathname={location.pathname}
        useStrapLine={selectedItem ? false : true}
      />

      {/* Structured Data for Google search */}
      {selectedItem && (
        <StructuredDataProduct
          isbn={selectedItem.isbn}
          name={selectedItem.title}
          author={selectedItem.author}
          imagePath={selectedItem.artwork}
          description={selectedItem.description}
          url={`${siteUrl}${location.pathname}`} // See: https://css-tricks.com/how-to-the-get-current-page-url-in-gatsby/#aa-method-3-generate-the-current-page-url-with-the-pathname-property-from-location-data
          currency={selectedItem.saleCurrency}
          price={selectedItem.salePriceIncTax}
        />
      )}

      {/* Content */}
      <GridLayout
        type="fluid"
        container={false}
        offsets={false}
        className="col-span-full"
      >
        <ContentItemsProvider contentItemsMap={contentItemsMap}>
          {/* Audiobooks */}
          {audiobooks?.map((audiobook) => {
            return (
              <ContentCard key={audiobook.ref} contentRef={audiobook.ref} />
            )
          })}
        </ContentItemsProvider>
        <div className="col-span-full flex justify-center">
          {isMounted && data && (
            <Button
              text="More audiobooks"
              shadowHeight={1}
              size="sm"
              loading={isFetchingNextPage}
              disabled={!hasNextPage}
              onClick={() => fetchNextPage()}
              className="w-52 mt-5 transition-opacity duration-500 ease-in-out"
            />
          )}
        </div>

        {/* Initial loading spinner */}
        <Spinner
          isLoading={isFetching && !isFetchingNextPage}
          className="absolute top-24"
        />
      </GridLayout>

      {showError && (
        <ModalDialog
          onPrimaryButtonClicked={() => {
            setShowError(false)
          }}
          message={
            <>
              <p>
                We're sorry but we couldn't load audiobooks from the store at
                this time.
              </p>
              <p className="mt-2">
                Please try again by refreshing your browser or contact our
                customer support.
              </p>
            </>
          }
        />
      )}
    </>
  )
}

export default StoreAudiobooks
StoreAudiobooks.whyDidYouRender = true

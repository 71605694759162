import React from "react"
import Helmet from "react-helmet"

/**
 * A simple component that uses Helmet to place Structured Data in the head,
 * for SEO data.
 *
 * See: https://graphicscove.com/how-to-add-structured-data-to-gatsby
 * See: https://json-ld.org/
 * See: https://search.google.com/test/rich-results for testing
 */
export function StructuredData({ children }) {
  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(children)}</script>
    </Helmet>
  )
}
